*{ -moz-box-sizing: border-box; box-sizing: border-box; overflow: hidden; }
@import "flex_def.css.less";

body{ margin: 0; padding: 0; }
site{ 
	display: block; width: 100vw; height: 100vh; overflow: auto; font-size: calc(1vw + 0.56vh ); 
	font-size: -webkit-calc(1vw + 0.56vh ); 
	position: relative; font-family: 'Kulim Park', sans-serif;
}
span,cell{ display: inline-block; }
h1{ font-weight: normal; margin: 0; padding: 0.5em 1em; }
p{ margin-top: 0; }

pic,icon{ display: inline-block; background: center center no-repeat; background-size: 100% 100%; }
/*
icon.demozoo, pic.demozoo{ background-image: url(../images/me_icons/icon_demozoo.png); }
icon.facebook, pic.facebook{ background-image: url(../images/me_icons/icon_facebook.png); }	
icon.soundcloud, pic.soundcloud{ background-image: url(../images/me_icons/icon_soundcloud.png); }	
*/
.flag_en{ background-image: url(../images/flag_en.png); }
.flag_fr{ background-image: url(../images/flag_fr.png); }

pic.ratio,icon.ratio{ background-size: 100% auto; }


input,select,textarea{ font-size: 1em; padding: 0.5em; border: 0.1em solid #000; background: #fff; }
input[readonly],select[readonly],textarea[readonly]{ background: #E8E8FF; }

.cta{ .inflex_x_center(); text-decoration: none; font-size: 100%; padding: 0.33em 1em; border-radius: 0.25em; margin: 1em 0; }
.cta_w{ .cta(); color: #000; background: #aaa; border: 1px solid #ccc; .gradient(linear; #aaa 48%, #ccc 48%); text-shadow: 1px 1px 0 #ccc; }
.cta_w:hover{ text-decoration: none; color: #980; }
.cta_b{ .cta(); color: #fff; background: #000; border: 1px solid #000; .gradient(linear; #000 48%, #555 48%); text-shadow: -1px -1px 0 #000; }
.cta_b:hover{ text-decoration: none; color: #fe0; }



header{ 
	.flex_between_center(); width: 100vw; height: 5em; position: fixed; top: 0; left: 0; z-index: 50;
	logo{ 
		padding: 1em;
		pic{ width: 6em; height: 3.42em; background-image: url(../images/dek_logo_n.png); }
	}	

	//menu DESKTOP
	menu{ 
		flex: 1; 	.inflex_right_center(); align-self: flex-start; margin: 0; 
		icon{ width: 2em; height: 1.25em;  }
		a.lgs{ padding: 0.25em 0.25em; }
		a{ 
			.inflex_center_center(); text-decoration: none; padding: 0.25em 2em; font-size: 0.88em;
			color: #000;
			lab{ display: block; padding: 0.25em 0; border-bottom: 1px solid rgba(0,0,0,0.2); }
		}
		a:last-child{ border-right: none; } 
	}
	
}

header.scroll{ 
	background: #fff; font-size: 66%; height: 4em; box-shadow: 0 1vh 1vh rgba(0, 0, 0,0.33); 
	logo{ padding: 0 1em; }
	menu{ align-self: inherit; }
	menu.desktop{
		a{ 
			font-size: 1.1em;
			lab{ border-bottom: none; }
		}	
	}
}


#page_home {
	#splash{ 
		.splash(); background-image: url(../images/splash_home_back.jpg); height: 100vh;
		txt{ 
			display: block; text-align: center; 
			h1{ font-size: 2.5em; text-transform: uppercase; }
		}
	}
	#strongpoints{
		in{
			.flex_around_stretch(); padding: 2em 0;
			article{
				.inflex_x_center(); flex-direction: column; width: 30%; border: 1px solid #ccc; padding: 1em;
				pic{ width: 100%; padding-top: 10em; background-color: #ccc; background-size: cover; }
				txt{ display: block; text-align: justify; font-size: 0.75em; font-weight: 200; color: #8DA1F0; }
			}
			article#fullstack pic{ background-image: url(../images/sp_fullstack.jpg); }
			article#cmstailor pic{ background-image: url(../images/sp_cmstailor.jpg); }
			article#pro pic{ background-image: url(../images/sp_pro.jpg); }
		}
	}	
	
	#fortheweb{
		background: url(../images/spiderweb.svg) center center no-repeat #f4f4f6; background-size: cover;
		in{
			.flex_around_center(); padding: 2em 0;
			cell{
				width: 38%;
				h1{ 
					font-size: 4em; text-align: right; 
					b{ color: #8DA1F0; }
				}
				li{ .flex_x_center(); margin: 1em; }
				li:before{ content: '\e932'; font-family: Material Icons; font-size: 1.5em; margin-right: 0.5em; color: #D2BEFD; }
			}
		}
	}	
	
	#forthevisual{
		background: url(../images/forthevisual.jpg) center center no-repeat; background-size: cover; background-attachment: fixed; color: #fff;
		in{
			.flex_around_center(); padding: 2em 0; background: rgba(19, 7, 79, 0.64);
			cell{
				width: 33%;
				h1{ 
					font-size: 4em; text-align: left; 
					b{ color: #FF70E2; }
				}
				li{ .flex_x_center(); margin: 1em; }
				li:before{ content: '\e3ae'; font-family: Material Icons; font-size: 1.5em; margin-right: 0.5em; color: #FF70E2; }
			}
		}
	}

	#devis{
		in{
			.flex_around_center(); border-bottom: 1px solid #000;
			gfx{ 
				display: inline-flex; align-self: flex-end;
				pic{ width: 33em; height: 28em; background-image: url(../images/dekmascott.svg) }
			}
			cell{
				.flex_center_center(); width: 25em; height: 25em; padding: 3em; color: #fff; background: rgba(27, 52, 94, 0.84); border-radius: 100%;
				div{ text-align: center; font-size: 1.5em; }
				a{ margin-bottom: 0.25em; }
				h1{ margin:0; padding: 0; text-transform: uppercase; }
			}
		}
	}
}

#page_contact{
	#splash{ .splash(); background-image: url(../images/splash_home_back.jpg); height: 33vh; }
	#ctczone{
		padding: 2em 0;
		in{
			.flex_around_center();
			cell{ width: 33%; }
			txt{ 
				.flex_center_center(); border: 1px solid #ccc; padding: 2em; font-size: 1.25em;
				small{ display: block; text-align: right; }
			}
			#contactform {
				div{
					.flex_x_center(); margin: 0.5em 0;
					input,select,textarea{ flex: 1; }
					textarea{ height: 6.5em; }
					label{ display: block; width: 25%; }
					button{ width: 75%; .flex_center_center(); margin-left: 25%; }
				}
				.ln{ display: none; }
			}
			.cfemail{ 
				width: 33%; 
				pic{ width: 100%; padding-top: 57%; background-image: url(../images/cf_pic.jpg); }
			}
		}
	}
}

ct{ .flex_center_top(); margin-top: 6em; }

#picviewer{ display: none; }

footer{ 
	.flex_around_top(); background: #000; color: #fff; padding: 2em 0 8em;
	logo{ 
		padding: 1em;
		pic{ width: 9em; height: 5.13em; background-image: url(../images/dek_logo_b.png); }
	}
	.sm{ font-size: 0.75em; }
	.sm h1{ padding: 0; text-align: center; }
	.sm a{ text-decoration: none; color: #eee; }
}


@keyframes scroller{
	0%{ margin-left: 100%; }
	100%{ margin-left: -100%; }
}

@import "style_responsive.css.less";

@import "style_admin.css.less";
@import "style_admin_responsive.css.less";
