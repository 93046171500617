@media (min-width: 1280px){ /*desktop*/
	.mobile{ display: none; }
	header .dummy{ display: none; }
}

@media (max-width: 1280px){ //tout mobile
	.desktop{ display: none; }
	header{
		height: 3.5em;
		logo{ font-size: 0.75em; }
		cell{ padding: 0.5em; 
			i{ color: #000; } 
		}
	}
	menu.mobile{
		padding: 0; margin: 0; height: 100vh;  background: #fff; border-right: 1px solid #000;
		position: fixed; top:0;  z-index: 25;
		in{
			.flex_center_center(); flex-direction: column; height: 100%;
			icon{ width: 2em; height: 1.25em;  }
			a{ 
				.inflex_center_center(); text-decoration: none; padding: 0.5em 1em;
				color: #000;
				lab{ display: block; padding: 0.25em 0; border-bottom: 1px solid rgba(0,0,0,0.2); }
			}
			a.lgs{ padding: 0.25em 0.25em; }
		}
	}

	#page_home{	
		#strongpoints in{
			flex-wrap: wrap; font-size: 1.25em;
			article{
				width: 92%; margin: 1em 0;
			}
		}
	}	
}

@media (max-width: 1280px) and (min-width:600px) and (orientation: landscape){ /* and (orientation: landscape)*/
	
	site{ font-size: calc(2vw + 0.56vh); }
	header .dummy{ display: inline-block; flex: 1; }
	
	menu.mobile{  width: 25vw; left: -26vw; }

	#page_home{	

		#fortheweb{
			in cell{ 
				width: 50%; 
				h1{ font-size: 3.5em; }
			}
			
		}
		#forthevisual{
			in cell{ 
				width: 50%; 
				h1{ font-size: 3.5em; }
			}
		}
		#devis{
			in cell{ font-size: 0.75em; margin-left: -12em; }
		}
	}

	#page_contact{
		#splash{ height: 25vh; }

		#ctczone{ 
			padding: 1em 0.5em;

			in{
				cell{ width: 60%; }
				txt{ width: 34%; font-size: 1em; align-self: flex-start; }
				
			}

		}

	}
}


@media (max-width: 1280px) and (orientation: portrait), (max-width:600px) and (orientation: landscape){ 
	site{ font-size: calc(3.5vw + 0.56vh); }
	header .dummy{ display: none; }
	
	menu.mobile{  width: 50vw; left: -51vw; }

	#page_home{	
		#fortheweb{
			in{ 
				flex-wrap: wrap;
				cell{ 
					width: 92%; 
					h1{ font-size: 3em; }
				}
			}
			
		}
		#forthevisual{
			in{ 
				flex-wrap: wrap;
				cell{ 
					width: 92%; 
					h1{ font-size: 3em; }
				}
			}
		}
		#devis{
			in {
				flex-wrap: wrap;
				gfx{ 
					order: 2; 
					pic{ height: 19em; }
				}
				cell{ font-size: 1em; order: 1; }
			}
		}		
	}

	#page_contact{
		#splash{ height: 15vh; }
		#ctczone{ 
			padding: 1em 0.5em;
			in{
				flex-wrap: wrap;
				cell{ width: 92%; }
				txt{ width: 92%; padding: 1em 2em; margin-bottom: 0.25em; }
				.cfemail{ width: 92%; }
			}
		}
	}

	footer{
		flex-direction: column; align-items: center;
		cell{ margin: 1em 0; }
	}
}

/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/


