.flex_x_top{
    display: flex; flex: none; align-items: flex-start;
}
.flex_x_center{
    display: flex; flex: none; align-items: safe center;
}
.flex_x_stretch{
    display: flex; flex: none; align-items: stretch;
}
.flex_x_bottom{
    display: flex; flex: none; align-items: flex-end;
}

.inflex_x_top{
    display: inline-flex; flex: none; align-items: flex-start;
}
.inflex_x_center{
    display: inline-flex; flex: none; align-items: safe center;
}
.inflex_x_stretch{
    display: inline-flex; flex: none; align-items: stretch;
}
.inflex_x_bottom{
    display: inline-flex; flex: none; align-items: flex-end;
}






.flex_center_top{
    .flex_x_top(); justify-content: center;
}
.flex_around_top{
    .flex_x_top(); justify-content: space-around;
}
.flex_between_top{
    .flex_x_top(); justify-content: space-between;
}
.flex_left_top{
    .flex_x_top(); justify-content: flex-start;
}
.flex_right_top{
    .flex_x_top(); justify-content: flex-end;
}



.flex_center_center{
    .flex_x_center(); justify-content: center;
}
.flex_around_center{
    .flex_x_center(); justify-content: space-around;
}
.flex_between_center{
    .flex_x_center(); justify-content: space-between;
}
.flex_left_center{
    .flex_x_center(); justify-content: flex-start;
}
.flex_right_center{
    .flex_x_center(); justify-content: flex-end;
}



.flex_center_stretch{
    .flex_x_stretch(); justify-content: center;
}
.flex_around_stretch{
    .flex_x_stretch(); justify-content: space-around;
}
.flex_between_stretch{
    .flex_x_stretch(); justify-content: space-between;
}
.flex_left_stretch{
    .flex_x_stretch(); justify-content: flex-start;
}
.flex_right_stretch{
    .flex_x_stretch(); justify-content: flex-end;
}



.flex_center_bottom{
    .flex_x_bottom(); justify-content: center;
}
.flex_around_bottom{
    .flex_x_bottom(); justify-content: space-around;
}
.flex_between_bottom{
    .flex_x_bottom(); justify-content: space-between;
}
.flex_left_bottom{
    .flex_x_bottom(); justify-content: flex-start;
}
.flex_right_bottom{
    .flex_x_bottom(); justify-content: flex-end;
}











.inflex_center_top{
    .inflex_x_top(); justify-content: center;
}
.inflex_around_top{
    .inflex_x_top(); justify-content: space-around;
}
.inflex_between_top{
    .inflex_x_top(); justify-content: space-between;
}
.inflex_left_top{
    .inflex_x_top(); justify-content: flex-start;
}
.inflex_right_top{
    .inflex_x_top(); justify-content: flex-end;
}



.inflex_center_center{
    .inflex_x_center(); justify-content: center;
}
.inflex_around_center{
    .inflex_x_center(); justify-content: space-around;
}
.inflex_between_center{
    .inflex_x_center(); justify-content: space-between;
}
.inflex_left_center{
    .inflex_x_center(); justify-content: flex-start;
}
.inflex_right_center{
    .inflex_x_center(); justify-content: flex-end;
}


.inflex_center_stretch{
    .inflex_x_stretch(); justify-content: center;
}
.inflex_around_stretch{
    .inflex_x_stretch(); justify-content: space-around;
}
.inflex_between_stretch{
    .inflex_x_stretch(); justify-content: space-between;
}
.inflex_left_stretch{
    .inflex_x_stretch(); justify-content: flex-start;
}
.inflex_right_stretch{
    .inflex_x_stretch(); justify-content: flex-end;
}



.inflex_center_bottom{
    .inflex_x_bottom(); justify-content: center;
}
.inflex_around_bottom{
    .inflex_x_bottom(); justify-content: space-around;
}
.inflex_between_bottom{
    .inflex_x_bottom(); justify-content: space-between;
}
.inflex_left_bottom{
    .inflex_x_bottom(); justify-content: flex-start;
}
.inflex_right_bottom{
    .inflex_x_bottom(); justify-content: flex-end;
}


.splash{
    .flex_around_center(); height: 50vh;
	background: center center no-repeat; background-size: cover; background-attachment: fixed; 
	gradient{ .flex_around_center(); width: 100%; height: 100%; }
    left{ display: inline-flex; flex: 1; justify-content: center; align-items: safe center; }
}

.outline(@color,@thick){
    text-shadow:    0 @thick 0 @color, @thick 0 0 @color, 0 -@thick 0 @color, -@thick 0 0 @color,
                    -@thick -@thick 0 @color, @thick -@thick 0 @color,  @thick @thick 0 @color, -@thick @thick 0 @color;
     
}
.outline_deep(@color,@thick){
    text-shadow:    0 @thick 0 @color, @thick 0 0 @color, 0 -@thick 0 @color, -@thick 0 0 @color,
                    -@thick -@thick 0 @color, @thick -@thick 0 @color,  @thick @thick 0 @color, -@thick @thick 0 @color,
                    1px 1px 0 @color, 2px 2px 0 @color, 3px 3px 0 @color, 4px 4px 0 @color, 5px 5px 0 @color, 6px 6px 0 @color;
     
}

.gradient(@type; @colors; @dir: 0deg; @prefixes: webkit, moz, ms, o; @index: length(@prefixes)) when (@index > 0) {
    .gradient(@type; @colors; @dir; @prefixes; (@index - 1));
 
    @prefix  : extract(@prefixes, @index);
    @dir-old : 90 - (@dir);
 
    background-image: ~"-@{prefix}-@{type}-gradient(@{dir-old}, @{colors})";
  & when ( @index = length(@prefixes) ) {
        background-image: ~"@{type}-gradient(@{dir}, @{colors})";
    }
}

.pixelated {
    -ms-interpolation-mode: nearest-neighbor; // IE 7+ (non-standard property)
    image-rendering: -webkit-optimize-contrast; // Safari 6, UC Browser 9.9
    image-rendering: -webkit-crisp-edges; // Safari 7+
    image-rendering: -moz-crisp-edges; // Firefox 3.6+
    image-rendering: -o-crisp-edges; // Opera 12
    image-rendering: pixelated; // Chrome 41+ and Opera 26+
}

.disabletext {
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}