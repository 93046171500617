* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.flex_x_top {
  display: flex;
  flex: none;
  align-items: flex-start;
}
.flex_x_center {
  display: flex;
  flex: none;
  align-items: safe center;
}
.flex_x_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
}
.flex_x_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
}
.inflex_x_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
}
.inflex_x_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
}
.inflex_x_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
}
.inflex_x_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
}
.flex_center_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.flex_around_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.flex_between_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.flex_left_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex_right_top {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex_center_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.flex_around_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.flex_between_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.flex_left_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.flex_right_center {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.flex_center_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.flex_around_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.flex_between_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.flex_left_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.flex_right_stretch {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.flex_center_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.flex_around_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.flex_between_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.flex_left_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex_right_bottom {
  display: flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.inflex_center_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
}
.inflex_around_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
}
.inflex_between_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-between;
}
.inflex_left_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-start;
}
.inflex_right_top {
  display: inline-flex;
  flex: none;
  align-items: flex-start;
  justify-content: flex-end;
}
.inflex_center_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
}
.inflex_around_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
.inflex_between_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
}
.inflex_left_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-start;
}
.inflex_right_center {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
}
.inflex_center_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: center;
}
.inflex_around_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
}
.inflex_between_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
}
.inflex_left_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-start;
}
.inflex_right_stretch {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
}
.inflex_center_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: center;
}
.inflex_around_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-around;
}
.inflex_between_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: space-between;
}
.inflex_left_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
}
.inflex_right_bottom {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-end;
}
.splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
.splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
.pixelated {
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -webkit-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
}
.disabletext {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}
body {
  margin: 0;
  padding: 0;
}
site {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  font-size: calc(1vw + 0.56vh);
  font-size: -webkit-calc(1.56vw);
  position: relative;
  font-family: 'Kulim Park', sans-serif;
}
span,
cell {
  display: inline-block;
}
h1 {
  font-weight: normal;
  margin: 0;
  padding: 0.5em 1em;
}
p {
  margin-top: 0;
}
pic,
icon {
  display: inline-block;
  background: center center no-repeat;
  background-size: 100% 100%;
}
/*
icon.demozoo, pic.demozoo{ background-image: url(../images/me_icons/icon_demozoo.png); }
icon.facebook, pic.facebook{ background-image: url(../images/me_icons/icon_facebook.png); }	
icon.soundcloud, pic.soundcloud{ background-image: url(../images/me_icons/icon_soundcloud.png); }	
*/
.flag_en {
  background-image: url(../images/flag_en.png);
}
.flag_fr {
  background-image: url(../images/flag_fr.png);
}
pic.ratio,
icon.ratio {
  background-size: 100% auto;
}
input,
select,
textarea {
  font-size: 1em;
  padding: 0.5em;
  border: 0.1em solid #000;
  background: #fff;
}
input[readonly],
select[readonly],
textarea[readonly] {
  background: #E8E8FF;
}
.cta {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
}
.cta_w {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
  color: #000;
  background: #aaa;
  border: 1px solid #ccc;
  background-image: -webkit-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -moz-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -ms-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: -o-linear-gradient(90deg, #aaa 48%, #ccc 48%);
  background-image: linear-gradient(0deg, #aaa 48%, #ccc 48%);
  text-shadow: 1px 1px 0 #ccc;
}
.cta_w:hover {
  text-decoration: none;
  color: #980;
}
.cta_b {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  font-size: 100%;
  padding: 0.33em 1em;
  border-radius: 0.25em;
  margin: 1em 0;
  color: #fff;
  background: #000;
  border: 1px solid #000;
  background-image: -webkit-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -moz-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -ms-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: -o-linear-gradient(90deg, #000 48%, #555 48%);
  background-image: linear-gradient(0deg, #000 48%, #555 48%);
  text-shadow: -1px -1px 0 #000;
}
.cta_b:hover {
  text-decoration: none;
  color: #fe0;
}
header {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
  width: 100vw;
  height: 5em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}
header logo {
  padding: 1em;
}
header logo pic {
  width: 6em;
  height: 3.42em;
  background-image: url(../images/dek_logo_n.png);
}
header menu {
  flex: 1;
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
  align-self: flex-start;
  margin: 0;
}
header menu icon {
  width: 2em;
  height: 1.25em;
}
header menu a.lgs {
  padding: 0.25em 0.25em;
}
header menu a {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  text-decoration: none;
  padding: 0.25em 2em;
  font-size: 0.88em;
  color: #000;
}
header menu a lab {
  display: block;
  padding: 0.25em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
header menu a:last-child {
  border-right: none;
}
header.scroll {
  background: #fff;
  font-size: 66%;
  height: 4em;
  box-shadow: 0 1vh 1vh rgba(0, 0, 0, 0.33);
}
header.scroll logo {
  padding: 0 1em;
}
header.scroll menu {
  align-self: inherit;
}
header.scroll menu.desktop a {
  font-size: 1.1em;
}
header.scroll menu.desktop a lab {
  border-bottom: none;
}
#page_home #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../images/splash_home_back.jpg);
  height: 100vh;
}
#page_home #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_home #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_home #splash txt {
  display: block;
  text-align: center;
}
#page_home #splash txt h1 {
  font-size: 2.5em;
  text-transform: uppercase;
}
#page_home #strongpoints in {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
  padding: 2em 0;
}
#page_home #strongpoints in article {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  flex-direction: column;
  width: 30%;
  border: 1px solid #ccc;
  padding: 1em;
}
#page_home #strongpoints in article pic {
  width: 100%;
  padding-top: 10em;
  background-color: #ccc;
  background-size: cover;
}
#page_home #strongpoints in article txt {
  display: block;
  text-align: justify;
  font-size: 0.75em;
  font-weight: 200;
  color: #8DA1F0;
}
#page_home #strongpoints in article#fullstack pic {
  background-image: url(../images/sp_fullstack.jpg);
}
#page_home #strongpoints in article#cmstailor pic {
  background-image: url(../images/sp_cmstailor.jpg);
}
#page_home #strongpoints in article#pro pic {
  background-image: url(../images/sp_pro.jpg);
}
#page_home #fortheweb {
  background: url(../images/spiderweb.svg) center center no-repeat #f4f4f6;
  background-size: cover;
}
#page_home #fortheweb in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  padding: 2em 0;
}
#page_home #fortheweb in cell {
  width: 38%;
}
#page_home #fortheweb in cell h1 {
  font-size: 4em;
  text-align: right;
}
#page_home #fortheweb in cell h1 b {
  color: #8DA1F0;
}
#page_home #fortheweb in cell li {
  display: flex;
  flex: none;
  align-items: safe center;
  margin: 1em;
}
#page_home #fortheweb in cell li:before {
  content: '\e932';
  font-family: Material Icons;
  font-size: 1.5em;
  margin-right: 0.5em;
  color: #D2BEFD;
}
#page_home #forthevisual {
  background: url(../images/forthevisual.jpg) center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
}
#page_home #forthevisual in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  padding: 2em 0;
  background: rgba(19, 7, 79, 0.64);
}
#page_home #forthevisual in cell {
  width: 33%;
}
#page_home #forthevisual in cell h1 {
  font-size: 4em;
  text-align: left;
}
#page_home #forthevisual in cell h1 b {
  color: #FF70E2;
}
#page_home #forthevisual in cell li {
  display: flex;
  flex: none;
  align-items: safe center;
  margin: 1em;
}
#page_home #forthevisual in cell li:before {
  content: '\e3ae';
  font-family: Material Icons;
  font-size: 1.5em;
  margin-right: 0.5em;
  color: #FF70E2;
}
#page_home #devis in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  border-bottom: 1px solid #000;
}
#page_home #devis in gfx {
  display: inline-flex;
  align-self: flex-end;
}
#page_home #devis in gfx pic {
  width: 33em;
  height: 28em;
  background-image: url(../images/dekmascott.svg);
}
#page_home #devis in cell {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 25em;
  height: 25em;
  padding: 3em;
  color: #fff;
  background: rgba(27, 52, 94, 0.84);
  border-radius: 100%;
}
#page_home #devis in cell div {
  text-align: center;
  font-size: 1.5em;
}
#page_home #devis in cell a {
  margin-bottom: 0.25em;
}
#page_home #devis in cell h1 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
#page_contact #splash {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  height: 50vh;
  background: center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url(../images/splash_home_back.jpg);
  height: 33vh;
}
#page_contact #splash gradient {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}
#page_contact #splash left {
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: safe center;
}
#page_contact #ctczone {
  padding: 2em 0;
}
#page_contact #ctczone in {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
}
#page_contact #ctczone in cell {
  width: 33%;
}
#page_contact #ctczone in txt {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 2em;
  font-size: 1.25em;
}
#page_contact #ctczone in txt small {
  display: block;
  text-align: right;
}
#page_contact #ctczone in #contactform div {
  display: flex;
  flex: none;
  align-items: safe center;
  margin: 0.5em 0;
}
#page_contact #ctczone in #contactform div input,
#page_contact #ctczone in #contactform div select,
#page_contact #ctczone in #contactform div textarea {
  flex: 1;
}
#page_contact #ctczone in #contactform div textarea {
  height: 6.5em;
}
#page_contact #ctczone in #contactform div label {
  display: block;
  width: 25%;
}
#page_contact #ctczone in #contactform div button {
  width: 75%;
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  margin-left: 25%;
}
#page_contact #ctczone in #contactform .ln {
  display: none;
}
#page_contact #ctczone in .cfemail {
  width: 33%;
}
#page_contact #ctczone in .cfemail pic {
  width: 100%;
  padding-top: 57%;
  background-image: url(../images/cf_pic.jpg);
}
ct {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: center;
  margin-top: 6em;
}
#picviewer {
  display: none;
}
footer {
  display: flex;
  flex: none;
  align-items: flex-start;
  justify-content: space-around;
  background: #000;
  color: #fff;
  padding: 2em 0 8em;
}
footer logo {
  padding: 1em;
}
footer logo pic {
  width: 9em;
  height: 5.13em;
  background-image: url(../images/dek_logo_b.png);
}
footer .sm {
  font-size: 0.75em;
}
footer .sm h1 {
  padding: 0;
  text-align: center;
}
footer .sm a {
  text-decoration: none;
  color: #eee;
}
@keyframes scroller {
  0% {
    margin-left: 100%;
  }
  100% {
    margin-left: -100%;
  }
}
@media (min-width: 1280px) {
  /*desktop*/
  .mobile {
    display: none;
  }
  header .dummy {
    display: none;
  }
}
@media (max-width: 1280px) {
  .desktop {
    display: none;
  }
  header {
    height: 3.5em;
  }
  header logo {
    font-size: 0.75em;
  }
  header cell {
    padding: 0.5em;
  }
  header cell i {
    color: #000;
  }
  menu.mobile {
    padding: 0;
    margin: 0;
    height: 100vh;
    background: #fff;
    border-right: 1px solid #000;
    position: fixed;
    top: 0;
    z-index: 25;
  }
  menu.mobile in {
    display: flex;
    flex: none;
    align-items: safe center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  menu.mobile in icon {
    width: 2em;
    height: 1.25em;
  }
  menu.mobile in a {
    display: inline-flex;
    flex: none;
    align-items: safe center;
    justify-content: center;
    text-decoration: none;
    padding: 0.5em 1em;
    color: #000;
  }
  menu.mobile in a lab {
    display: block;
    padding: 0.25em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  menu.mobile in a.lgs {
    padding: 0.25em 0.25em;
  }
  #page_home #strongpoints in {
    flex-wrap: wrap;
    font-size: 1.25em;
  }
  #page_home #strongpoints in article {
    width: 92%;
    margin: 1em 0;
  }
}
@media (max-width: 1280px) and (min-width: 600px) and (orientation: landscape) {
  /* and (orientation: landscape)*/
  site {
    font-size: calc(2vw + 0.56vh);
  }
  header .dummy {
    display: inline-block;
    flex: 1;
  }
  menu.mobile {
    width: 25vw;
    left: -26vw;
  }
  #page_home #fortheweb in cell {
    width: 50%;
  }
  #page_home #fortheweb in cell h1 {
    font-size: 3.5em;
  }
  #page_home #forthevisual in cell {
    width: 50%;
  }
  #page_home #forthevisual in cell h1 {
    font-size: 3.5em;
  }
  #page_home #devis in cell {
    font-size: 0.75em;
    margin-left: -12em;
  }
  #page_contact #splash {
    height: 25vh;
  }
  #page_contact #ctczone {
    padding: 1em 0.5em;
  }
  #page_contact #ctczone in cell {
    width: 60%;
  }
  #page_contact #ctczone in txt {
    width: 34%;
    font-size: 1em;
    align-self: flex-start;
  }
}
@media (max-width: 1280px) and (orientation: portrait), (max-width: 600px) and (orientation: landscape) {
  site {
    font-size: calc(3.5vw + 0.56vh);
  }
  header .dummy {
    display: none;
  }
  menu.mobile {
    width: 50vw;
    left: -51vw;
  }
  #page_home #fortheweb in {
    flex-wrap: wrap;
  }
  #page_home #fortheweb in cell {
    width: 92%;
  }
  #page_home #fortheweb in cell h1 {
    font-size: 3em;
  }
  #page_home #forthevisual in {
    flex-wrap: wrap;
  }
  #page_home #forthevisual in cell {
    width: 92%;
  }
  #page_home #forthevisual in cell h1 {
    font-size: 3em;
  }
  #page_home #devis in {
    flex-wrap: wrap;
  }
  #page_home #devis in gfx {
    order: 2;
  }
  #page_home #devis in gfx pic {
    height: 19em;
  }
  #page_home #devis in cell {
    font-size: 1em;
    order: 1;
  }
  #page_contact #splash {
    height: 15vh;
  }
  #page_contact #ctczone {
    padding: 1em 0.5em;
  }
  #page_contact #ctczone in {
    flex-wrap: wrap;
  }
  #page_contact #ctczone in cell {
    width: 92%;
  }
  #page_contact #ctczone in txt {
    width: 92%;
    padding: 1em 2em;
    margin-bottom: 0.25em;
  }
  #page_contact #ctczone in .cfemail {
    width: 92%;
  }
  footer {
    flex-direction: column;
    align-items: center;
  }
  footer cell {
    margin: 1em 0;
  }
}
/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/
#login {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  height: 33vw;
}
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #ddd;
}
.bootstrap-datetimepicker-widget > ul {
  padding: 0;
}
.bootstrap-datetimepicker-widget .collapse {
  display: block;
}
#admin site {
  display: flex;
  flex: none;
  align-items: stretch;
}
#admin site menu {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  width: 20%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background: #000;
  color: #fff;
}
#admin site menu logo {
  padding: 1em;
  text-align: center;
}
#admin site menu logo pic {
  width: 4.5em;
  height: 2.56em;
  background-image: url(../images/dek_logo_b.png);
}
#admin site menu a {
  display: inline-block;
  text-decoration: none;
  padding: 1em;
  text-align: center;
  color: #fff;
}
#admin site menu a:hover {
  background: rgba(255, 255, 255, 0.12);
}
#admin site menu a.logout {
  background: rgba(173, 65, 2, 0.5);
}
#admin site menu a.logout:hover {
  background: rgba(255, 17, 0, 0.5);
}
#admin site pointagewidget {
  display: flex;
  flex: none;
  align-items: stretch;
  justify-content: space-around;
  flex-direction: column;
  background: rgba(25, 74, 136, 0.5);
  font-size: 0.66em;
  padding: 0.5em 1em 1em;
}
#admin site pointagewidget h3,
#admin site pointagewidget h4 {
  margin: 0;
  padding: 0.5em 0;
}
#admin site pointagewidget h4 {
  text-align: center;
}
#admin site pointagewidget select {
  color: #194988;
}
#admin site pointagewidget bt {
  display: block;
  text-align: center;
}
#admin site pointagewidget a {
  text-decoration: none;
  padding: 0.5em;
  text-align: center;
  color: #fff;
  border: 1px solid #333;
  width: 75%;
  font-size: 2em;
}
#admin site pointagewidget a:hover {
  border: 1px solid #fff;
}
#admin site pointagewidget a.in {
  background-image: -webkit-linear-gradient(90deg, #050 42%, #070 48%);
  background-image: -moz-linear-gradient(90deg, #050 42%, #070 48%);
  background-image: -ms-linear-gradient(90deg, #050 42%, #070 48%);
  background-image: -o-linear-gradient(90deg, #050 42%, #070 48%);
  background-image: linear-gradient(0deg, #050 42%, #070 48%);
}
#admin site pointagewidget a.out {
  background-image: -webkit-linear-gradient(90deg, #55000b 42%, #77001e 48%);
  background-image: -moz-linear-gradient(90deg, #55000b 42%, #77001e 48%);
  background-image: -ms-linear-gradient(90deg, #55000b 42%, #77001e 48%);
  background-image: -o-linear-gradient(90deg, #55000b 42%, #77001e 48%);
  background-image: linear-gradient(0deg, #55000b 42%, #77001e 48%);
}
#admin site main {
  flex: 1;
  position: relative;
}
#admin site filter {
  position: fixed;
  width: 80%;
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-around;
  flex-wrap: wrap;
  background: #eee;
  font-size: 0.66em;
  overflow: visible;
}
#admin site filter cell {
  overflow: visible;
}
#admin site filter.top {
  top: 0;
  height: 12vh;
}
#admin site filter.bottom {
  top: 93vh;
  height: 7vh;
}
#admin site nbResults {
  display: block;
  padding: 0.5em;
  background: #ddf;
  font-size: 0.75em;
}
#admin site nbResults dt {
  display: inline;
}
#admin site liste {
  display: flex;
  flex: none;
  align-items: stretch;
  min-height: 100vh;
  flex-direction: column;
  padding: 12vh 0 7vh 0;
}
#admin site table {
  width: 100%;
  font-size: 0.66em;
  overflow: visible;
}
#admin site table th {
  background: #ccc;
  padding: 0.25em;
  border-right: 1px solid #aaa;
  text-align: center;
}
#admin site table #tot th {
  background: #ec9;
}
#admin site table #tot div {
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.5);
}
#admin site table #tot cell {
  padding: 0.1em 1em;
}
#admin site table #tot #ttc_nopayed {
  background: #f99;
}
#admin site table #tot #ttc_payed {
  background: #8f8;
}
#admin site table td {
  padding: 0.25em;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ccc;
  text-align: center;
  overflow: visible;
}
#admin site table td img,
#admin site table td pic,
#admin site table td icon {
  max-height: 1.5em;
}
#admin site table .packed {
  width: 1px;
}
#admin site table .num {
  width: 6em;
}
#admin site table .nowrap {
  white-space: nowrap;
}
#admin site table odv {
  font-size: 0.75em;
  color: #08f;
}
#admin site .tri {
  cursor: pointer;
}
#admin site .tri:after {
  content: '\2B0D';
  display: inline-block;
  margin-left: 0.1vw;
  opacity: 0.5;
  float: right;
}
#admin site .tri.ASC:after {
  content: '\25BE';
}
#admin site .tri.DESC:after {
  content: '\25B4';
}
#admin site datepicker {
  display: inline-flex;
  flex: none;
  align-items: safe center;
  background: #fff;
  border: 1px solid #000;
}
#admin site datepicker span {
  display: flex;
  flex: none;
  align-items: safe center;
  width: 100%;
}
#admin site datepicker input {
  background: none;
  border: none;
  flex: 1;
}
#admin site datepicker i {
  margin: 0 0.25em;
}
#admin site #dashboard h4 {
  padding: 0 2.5em;
  color: #2a2;
}
#admin site #dashboard h4 small {
  color: #000;
}
#admin site #dashboard in {
  display: flex;
  flex: none;
  align-items: stretch;
  font-size: 0.55em;
  height: 13em;
  margin-bottom: 1em;
}
#admin site #dashboard in axisy {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid #000;
}
#admin site #dashboard in axisy cell {
  text-align: center;
  padding: 0 0.25em;
}
#admin site #dashboard in axisx {
  border-top: 1px solid #000;
  width: 8.8em;
  height: 1.25em;
  padding: 0 0.5em;
  text-align: center;
}
#admin site #dashboard in bardata {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  justify-content: flex-end;
  flex-direction: column;
}
#admin site #dashboard in bardata barct {
  display: inline-flex;
  flex: none;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
  padding: 0 1em;
}
#admin site #dashboard in bardata barct bar {
  width: 100%;
  background: #2a2;
  overflow: visible;
}
#admin site #dashboard in bardata barct bar nm {
  display: block;
  transform: translate(0, -1.5em);
  font-size: 0.88em;
  text-align: center;
}
#admin site #dashboard .lastYear {
  background: #eee;
}
#admin site #dashboard .lastYear h4 {
  color: #93c;
}
#admin site #dashboard .lastYear bar {
  background: #93c;
}
#admin site #pointage md {
  display: inline-flex;
  flex-direction: row-reverse;
  font-size: 0.88em;
}
#admin site #pointage md cell {
  display: inline-block;
  width: auto;
  padding: 0.5em;
  margin: 0.33em;
  border: 1px solid #bbb;
  cursor: pointer;
}
#admin site #pointage .mouv0 {
  background: #7f7;
  color: #333;
}
#admin site #pointage .mouv0:before {
  content: '\2615';
  margin-right: 0.5em;
  font-size: 100%;
}
#admin site #pointage .mouv1 {
  background: #f9c;
  color: #333;
}
#admin site #pointage .mouv1:before {
  content: '\26AB';
  margin-right: 0.5em;
  font-size: 100%;
}
#admin site edit {
  display: inline-flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
  background: #ddf;
  font-size: 0.75em;
  width: 0;
}
#admin site edit editheader {
  display: flex;
  flex: none;
  align-items: stretch;
}
#admin site edit editheader cell {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  background: #c33;
  width: 2em;
  height: 2em;
}
#admin site edit editheader cell i {
  color: #fff;
}
#admin site edit editheader h1 {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  flex: 1;
  margin: 0;
  padding: 0;
  font-size: 1em;
  background: rgba(0, 0, 0, 0.2);
}
#admin site edit onglets {
  display: flex;
  flex: none;
  align-items: stretch;
  background: #888787;
  padding-top: 0.25em;
}
#admin site edit onglets a {
  text-decoration: none;
  display: inline-flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  flex: 1;
  margin-bottom: -1px;
  color: #666;
  background: #aaaaff;
  border: 1px solid #7777ff;
  border-bottom: none;
  border-radius: 0.5em 0.5em 0 0;
}
#admin site edit onglets a.in {
  background: #ddf;
}
#admin site edit onglets a:first-child {
  border-left: none;
}
#admin site edit onglets a:last-child {
  border-right: none;
}
#admin site edit editmain {
  flex: 1;
}
#admin site edit editmain .epanel {
  display: none;
  width: 100%;
}
#admin site edit editmain .epanel:first-child {
  display: block;
}
#admin site edit editmain div {
  display: flex;
  flex: none;
  align-items: safe center;
  padding: 0.5em 1em;
}
#admin site edit editmain div label {
  display: flex;
  flex: none;
  align-items: safe center;
  width: 33%;
}
#admin site edit editmain div input,
#admin site edit editmain div select,
#admin site edit editmain div textarea,
#admin site edit editmain div datepicker {
  flex: 1;
}
#admin site edit editmain div addtxt {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: flex-end;
  min-width: 1.5em;
  font-weight: bold;
}
#admin site edit buttons {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: space-between;
  padding: 0.25em 1em;
  font-size: 0.88em;
}
#admin site edit buttons .cancel {
  background-image: -webkit-linear-gradient(90deg, #000 48%, #744 48%);
  background-image: -moz-linear-gradient(90deg, #000 48%, #744 48%);
  background-image: -ms-linear-gradient(90deg, #000 48%, #744 48%);
  background-image: -o-linear-gradient(90deg, #000 48%, #744 48%);
  background-image: linear-gradient(0deg, #000 48%, #744 48%);
}
#admin site edit buttons .sclose {
  background-image: -webkit-linear-gradient(90deg, #000 48%, #674 48%);
  background-image: -moz-linear-gradient(90deg, #000 48%, #674 48%);
  background-image: -ms-linear-gradient(90deg, #000 48%, #674 48%);
  background-image: -o-linear-gradient(90deg, #000 48%, #674 48%);
  background-image: linear-gradient(0deg, #000 48%, #674 48%);
}
#admin site edit buttons .skeep {
  background-image: -webkit-linear-gradient(90deg, #000 48%, #476 48%);
  background-image: -moz-linear-gradient(90deg, #000 48%, #476 48%);
  background-image: -ms-linear-gradient(90deg, #000 48%, #476 48%);
  background-image: -o-linear-gradient(90deg, #000 48%, #476 48%);
  background-image: linear-gradient(0deg, #000 48%, #476 48%);
}
#admin site edit #topay {
  background: #000;
  color: #fff;
}
#admin site edit #topay span {
  display: flex;
  flex: none;
  align-items: safe center;
  color: #fd2;
}
#admin site edit #topay dt {
  display: inline;
}
#admin site edit #topay dvsym {
  display: inline-block;
  font-size: 0.88em;
  padding-left: 0.5em;
}
#admin site jsontab {
  display: block;
  background: rgba(255, 255, 255, 0.66);
  font-size: 1.1em;
}
#admin site jsontab action {
  display: none;
}
#admin site jsontab h1 {
  margin: 0;
  padding: 0.25em 1em;
  font-size: 1.25em;
}
#admin site jsontab input {
  border: none;
  width: 100%;
}
#admin site jsontab .addtxt {
  white-space: nowrap;
}
#admin site jsontab .addtxt input {
  width: calc(100% - 1.5em);
}
#admin site jsontab td {
  padding: 0;
}
#admin site jsontab a {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  padding: 0.33em;
  color: #c33;
}
#admin site jsontab #add td {
  padding: 1em 0.25em;
}
#admin site jsontab #add input {
  background: #6ff;
}
#admin site jsontab #add a {
  background: #383;
  color: #fff;
}
#admin site multibutton {
  display: inline-block;
  position: relative;
  overflow: visible;
}
#admin site multibutton a {
  display: flex;
  flex: none;
  align-items: safe center;
  text-decoration: none;
  color: #000;
  padding: 0.33em 0.75em;
  background: #eee;
  font-size: 1em;
  border: 1px solid #ccc;
}
#admin site multibutton hr {
  border: none;
  width: 100%;
  height: 0.25vh;
  background: #ccc;
  margin: 0;
}
#admin site multibutton a lab {
  flex: 1;
  text-align: center;
  padding: 0 0.5em;
  white-space: nowrap;
}
#admin site multibutton img,
#admin site multibutton icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
}
#admin site multibutton .material-icons {
  font-size: 1em;
}
#admin site multibutton bthead {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: row;
}
#admin site multibutton bthead a {
  box-shadow: 0 0 1.5em #fff inset;
}
#admin site multibutton bthead a:last-child {
  border-left: none;
  padding: 0.5em 0.5em;
  box-shadow: none;
}
#admin site multibutton a:hover {
  background: #F2D525;
  border: 1px solid #F2D525;
}
#admin site multibutton bthead a:last-child:hover {
  background: #F2D525;
  border: 1px solid #F2D525;
  border-left: none;
}
#admin site multibutton #btlist {
  display: none;
  position: absolute;
  left: 0;
  border: 1px solid #ccc;
  z-index: 4;
  min-width: 100%;
}
#admin site multibutton #btlist in {
  display: flex;
  flex: none;
  align-items: stretch;
  flex-direction: column;
}
#admin site multibutton #btlist a {
  display: flex;
  flex: none;
  align-items: safe center;
  border-bottom: 1px solid #eee;
}
#admin site multibutton #btlist a:first-child {
  color: #c33;
}
#admin site multibutton #btlist a lab {
  flex: 1;
  text-align: center;
}
#admin site multibutton #btlist cell {
  display: flex;
  flex: none;
  align-items: stretch;
  background: #eee;
  border-bottom: 1px solid #aaa;
  padding: 0.25em 0.5em;
}
#admin site multibutton #btlist cell lab {
  display: inline-block;
  white-space: nowrap;
  padding-right: 0.5em;
  font-size: 0.75em;
}
#admin site multibutton #btlist cell dom {
  display: inline-block;
  white-space: nowrap;
  min-width: 10em;
}
#admin site multibutton #btlist .confirm {
  width: 100%;
}
#admin site multibutton #btlist .confirm a {
  display: inline-block;
}
#admin site multirange {
  width: 10vw;
}
#admin site multiple {
  font-size: 0.75vw;
}
#admin site multiple disp {
  font-size: 0.75vw;
}
#admin site multiple disp scroll {
  padding: 0.5vw;
}
#admin site multiple img {
  width: 1.5vw;
  height: 2vh;
  margin: -0.25vh 0.5vw -0.25vh 0;
}
#admin .editPop main {
  font-size: 0.66em;
}
#admin .editPop filter {
  width: 50%;
}
#admin .editPop edit {
  width: 30%;
}
#admin .bootstrap-datetimepicker-widget .timepicker-picker .btn {
  display: flex;
  flex: none;
  align-items: safe center;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
  border: 1px solid #aaa;
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
}
#admin .bootstrap-datetimepicker-widget .timepicker-picker .btn i {
  display: inline-block;
  width: 14px;
  aspect-ratio: 1;
  margin-top: 1px;
  line-height: 14px;
  background-image: url("../images/glyphicons-halflings.png");
  background-repeat: no-repeat;
}
#admin .bootstrap-datetimepicker-widget .timepicker-picker .btn i.icon-chevron-up {
  background-position: -288px -120px;
}
#admin .bootstrap-datetimepicker-widget .timepicker-picker .btn i.icon-chevron-down {
  background-position: -313px -119px;
}
#admin .bootstrap-datetimepicker-widget .timepicker-picker span[class^="timepicker-"] {
  height: 2em;
  line-height: 2em;
}
@media (min-width: 1280px) {
  /*desktop*/
}
/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/
