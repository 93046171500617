@media (min-width: 1280px){ /*desktop*/

}


@media (max-width: 1280px) and (orientation: landscape){ 

}


@media (max-width: 1280px) and (orientation: portrait){ 

}

/*
@media (min-device-aspect-ratio: 13/9), (min-aspect-ratio: 13/9){


}
*/


