#login{ .flex_center_center(); height: 33vw; }

.datepicker table tr td.new, .datepicker table tr td.old{ color: #ddd; }
.bootstrap-datetimepicker-widget > ul{ padding: 0; }
.bootstrap-datetimepicker-widget .collapse{ display: block; }

@editBack: #ddf;

#admin{
    site{
        .flex_x_stretch();
        menu{
            .flex_x_stretch(); flex-direction: column; width: 20%; min-height: 100%; margin: 0; padding: 0;
            background: #000; color: #fff;
            logo{ 
                padding: 1em; text-align: center;
                pic{ width: 4.5em; height: 2.56em; background-image: url(../images/dek_logo_b.png); }
            }
            a{ display: inline-block; text-decoration: none; padding: 1em; text-align: center; color: #fff; }
            a:hover{ background: rgba(255, 255, 255, 0.12) }
            a.logout{ background: rgba(173, 65, 2, 0.5) }
            a.logout:hover{ background: rgba(255, 17, 0, 0.5) }
        }
        pointagewidget{  
            .flex_around_stretch(); flex-direction: column; background: rgba(25, 74, 136, 0.5); font-size: 0.66em; padding: 0.5em 1em 1em;
            h3,h4{ margin:0; padding: 0.5em 0; }
            h4{ text-align: center; }
            select{ color: #194988; }
            bt{ display: block; text-align: center; }
            a{ text-decoration: none; padding: 0.5em; text-align: center; color: #fff; border: 1px solid #333; width: 75%; font-size: 2em; }
            a:hover{ border: 1px solid #fff; }
            a.in{ .gradient(linear; #050 42%, #070 48%); }
            a.out{ .gradient(linear; rgb(85, 0, 11) 42%, rgb(119, 0, 30) 48%); }
        }

        main{
            flex:1; position:relative;
        }
        filter{
            position: fixed; width: 80%; .flex_around_center(); flex-wrap: wrap; background: #eee; font-size: 0.66em; overflow: visible;
            cell{ overflow: visible; }
        }
        filter.top{ top: 0; height: 12vh; }
        filter.bottom{ top: 93vh; height: 7vh;  }
        nbResults{ 
            display: block; padding: 0.5em; background: #ddf; font-size: 0.75em; 
            dt{ display: inline; }
        }
        liste{
            .flex_x_stretch(); min-height: 100vh; flex-direction: column; padding: 12vh 0 7vh 0;
        }
        table{ 
            width: 100%; font-size: 0.66em; overflow: visible;
            th{ background: #ccc; padding: 0.25em; border-right: 1px solid #aaa; text-align: center; }
            #tot th{ background: #ec9; }
            #tot div{ font-size: 0.75em; color: rgba(0, 0, 0, 0.5); }
            #tot cell{ padding: 0.1em 1em; }
            #tot #ttc_nopayed{ background: #f99; }
            #tot #ttc_payed{ background: #8f8; }
            td{ padding: 0.25em; border-right: 1px solid #ddd; border-bottom: 1px solid #ccc; text-align: center; overflow: visible; }
            td img, td pic, td icon{ max-height: 1.5em; }
            .packed{ width: 1px; }
            .num{ width: 6em; }
            .nowrap{ white-space: nowrap; }
            odv{ font-size: 0.75em; color: #08f; }
        }
        .tri{ cursor: pointer; }
        .tri:after{ content: '\2B0D'; display: inline-block; margin-left: 0.1vw; opacity: 0.5; float: right; }
        .tri.ASC:after{ content: '\25BE'; }
        .tri.DESC:after{ content: '\25B4';  }

        datepicker{
            .inflex_x_center; background: #fff; border: 1px solid #000;
            span{ .flex_x_center; width: 100%; }
            input{ background: none; border: none; flex: 1; }
            i{ margin: 0 0.25em; }
        }

        #dashboard{
            h4{ 
                padding: 0 2.5em; color: #2a2; 
                small{ color: #000; }
            }
            in{
                .flex_x_stretch(); font-size: 0.55em; height: 13em; margin-bottom: 1em;
                axisy{
                    .inflex_between_stretch(); flex-direction: column; border-right: 1px solid #000; 
                    cell{  text-align: center; padding: 0 0.25em; }
                }
                axisx{
                    border-top: 1px solid #000; width: 8.8em; height: 1.25em; padding: 0 0.5em; text-align: center;
                }
                bardata{
                    .inflex_right_stretch(); flex-direction: column;
                    barct{ 
                        .inflex_left_bottom(); flex: 1;  padding: 0 1em;
                        bar{ 
                            width: 100%; background: #2a2; overflow: visible;
                            nm{ display: block; transform: translate(0,-1.5em); font-size: 0.88em; text-align: center; }
                        }
                    }
                }

            }
            .lastYear{
                background: #eee;
                h4{ color: #93c; }
                bar{ background: #93c; }
            }
        }

        #pointage{
            md{ 
                display: inline-flex; flex-direction: row-reverse; font-size: 0.88em;
                cell{ display: inline-block; width: auto; padding: 0.5em; margin: 0.33em; border: 1px solid #bbb; cursor: pointer; }
            }
            .mouv0{ background: #7f7; color: #333; }
            .mouv0:before{ content: '\2615'; margin-right: 0.5em; font-size: 100%; }
            .mouv1{ background: #f9c; color: #333; }
            .mouv1:before{ content: '\26AB'; margin-right: 0.5em; font-size: 100%; }
        }


        edit{
            .inflex_x_stretch(); flex-direction: column; background: @editBack; font-size: 0.75em; width: 0;
            editheader{ 
                .flex_x_stretch(); 
                cell{ .flex_center_center(); background: #c33; width: 2em; height: 2em; }
                cell i{ color: #fff; }
                h1{ .flex_center_center(); flex: 1; margin:0; padding: 0; font-size: 1em; background: rgba(0, 0, 0, 0.2); }
            }
            onglets{
                .flex_x_stretch(); background: rgb(136, 135, 135); padding-top: 0.25em;
                a{ 
                    text-decoration: none; .inflex_center_center(); flex: 1; margin-bottom: -1px; color: #666;
                    background: darken(@editBack, 10%); border: 1px solid darken(@editBack, 20%); border-bottom: none; border-radius: 0.5em 0.5em 0 0;
                }
                a.in{ background: @editBack; }
                a:first-child{ border-left: none; }
                a:last-child{ border-right: none; }
            }
            editmain{
                flex: 1;
                .epanel{ display: none; width: 100%; }
                .epanel:first-child{ display: block;  }
                div{ 
                    .flex_x_center(); padding: 0.5em 1em;
                    label{ .flex_x_center; width: 33%; }
                    input,select,textarea,datepicker{ flex: 1 }
                    addtxt{ .flex_right_center(); min-width: 1.5em; font-weight: bold;  }
                }
            }
            buttons{
                .flex_between_center(); padding: 0.25em 1em; font-size: 0.88em;
                .cancel{ .gradient(linear; #000 48%, #744 48%) }
                .sclose{ .gradient(linear; #000 48%, #674 48%) }
                .skeep{ .gradient(linear; #000 48%, #476 48%) }
            }
            #topay{ 
                background: #000; color: #fff; 
                span{ .flex_x_center(); color: #fd2; }
                dt{ display: inline; }
                dvsym{ display: inline-block; font-size: 0.88em; padding-left: 0.5em; }
            }
        }

        jsontab{
            display: block; background: rgba(255, 255, 255, 0.66); font-size: 1.1em;
            action{ display: none; }
            h1{ margin: 0; padding: 0.25em 1em; font-size: 1.25em; }
            input{ border: none; width: 100%; }
            .addtxt{
                white-space: nowrap;
                input{ width: calc( 100% - 1.5em ); }
            }    
            td{ padding: 0; }
            a{ .flex_center_center(); padding: 0.33em; color: #c33;  }
            #add{
                td{  padding: 1em 0.25em; }
                input{ background: #6ff; }
                a{ background: #383; color: #fff; }
            } 
        }

        multibutton{ display: inline-block; position: relative; overflow: visible; }
        multibutton a{ 
            .flex_x_center(); text-decoration: none; color: #000;  padding: 0.33em 0.75em; background: #eee; font-size: 1em; border: 1px solid #ccc; 
        }
        multibutton hr{ border: none; width: 100%; height: 0.25vh; background: #ccc; margin: 0; }
        multibutton a lab{ flex:1; text-align: center; padding: 0 0.5em; white-space: nowrap; }
        multibutton img, multibutton icon{ width: 1em; height: 1em; vertical-align: middle; }
        multibutton .material-icons{ font-size: 1em; }
        multibutton bthead{ .flex_x_stretch(); flex-direction: row; }
        multibutton bthead a{ box-shadow: 0 0 1.5em #fff inset; }
        multibutton bthead a:last-child{ border-left: none; padding: 0.5em 0.5em; box-shadow: none; }
        multibutton a:hover{  background: #F2D525; border: 1px solid #F2D525; }
        multibutton bthead a:last-child:hover{  background: #F2D525; border: 1px solid #F2D525; border-left: none; }
        multibutton #btlist{ display: none; position: absolute; left: 0;  border: 1px solid #ccc; z-index: 4; min-width: 100%; }
        multibutton #btlist in{ .flex_x_stretch(); flex-direction: column; }
        multibutton #btlist a{ .flex_x_center(); border-bottom: 1px solid #eee;  }
        multibutton #btlist a:first-child{ color: #c33; }
        multibutton #btlist a lab{ flex: 1; text-align: center; }
        multibutton #btlist cell{ .flex_x_stretch();  background: #eee; border-bottom: 1px solid #aaa; padding: 0.25em 0.5em; }
        multibutton #btlist cell lab{ display: inline-block; white-space: nowrap; padding-right: 0.5em; font-size: 0.75em; }
        multibutton #btlist cell dom{ display: inline-block; white-space: nowrap; min-width: 10em; }
        multibutton #btlist .confirm{ width: 100%; }
        multibutton #btlist .confirm a{ display: inline-block; }

        multirange{ width: 10vw; }
        multiple{ font-size: 0.75vw; }
        multiple disp{ font-size: 0.75vw; }
        multiple disp scroll{ padding: 0.5vw; }
        multiple img{ width: 1.5vw; height: 2vh; margin: -0.25vh 0.5vw -0.25vh 0; }


    }
    
    .editPop{
        main{ font-size: 0.66em; }
        filter{ width: 50%; }
        edit{ width: 30%; }
    }

    .bootstrap-datetimepicker-widget{
        .timepicker-picker{
            .btn{
                .flex_center_center(); width: 100%; padding: 0.5em;
                border: 1px solid #aaa; background-image: linear-gradient(to bottom, #ffffff, #e6e6e6); background-repeat: repeat-x;
                i{
                    display: inline-block;
                    width: 14px;
                    aspect-ratio: 1/1;
                    margin-top: 1px;
                    line-height: 14px;
                    background-image: url("../images/glyphicons-halflings.png");
                    background-repeat: no-repeat;
                }
                i.icon-chevron-up{
                    background-position: -288px -120px;
                }
                i.icon-chevron-down{
                    background-position: -313px -119px;
                }
            }
            span[class^="timepicker-"]{
                height: 2em;
                line-height: 2em;
            }
        }
    }
}